@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100..700;1,100..700&display=swap');

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;
    --muted: 210 40% 96.1%;
    --muted-foreground: 0 0% 32.2%;
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;
    --tw-primary: 222.2 47.4% 11.2%;
    --tw-primary-foreground: 210 40% 98%;
    --tw-secondary: 210 40% 96.1%;
    --tw-secondary-foreground: 222.2 47.4% 11.2%;
    --tw-accent: 210 40% 96.1%;
    --tw-accent-foreground: 222.2 47.4% 11.2%;
    --tw-destructive: 354 70.5% 53.5%;
    --tw-destructive-foreground: 210 40% 98%;
    --ring: 215 20.2% 65.1%;
    --radius: 0.5rem;
  }

  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;
    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;
    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;
    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;
    --border: 216 34% 17%;
    --input: 216 34% 17%;
    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;
    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;
    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;
    --ring: 216 34% 17%;
  }
}

@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply font-sans antialiased bg-background text-foreground;
  }

  /* 
  These base classes bring back bootstrap styling overidden by tw. 
  We should remove these if we decide to remove bootstrap 
  */
  svg {
    @apply inline-block;
  }

  button {
    padding-inline: 6px;
    padding-block: 1px;
    color: revert-layer;
  }

  img,
  video {
    display: revert-layer;
    max-width: revert-layer;
    height: revert-layer;
  }

  ol,
  ul,
  menu {
    list-style: revert-layer;
    margin: 0;
    padding: 0;
  }

  .tw-overflow-auto {
    overflow: auto;
  }
}